<template>
  <v-row justify="center">
    <v-col cols="10" class="white pa-6 rounded-lg">
      <v-row class="ma-0">
        <h2 class="text-h4 font-weight-bold">Pagos</h2>
      </v-row>

      <v-row align="center">
        <v-col cols="12" sm="6" md="4">
          <v-file-input
            v-model="selectXlsx"
            outlined
            dense
            hide-details="auto"
            accept=".csv, .xlsx, xlsm, xls"
            placeholder="Archivo de pagos"
            prepend-icon=""
            prepend-inner-icon="mdi-paperclip"
          ></v-file-input>
        </v-col>
        <v-col cols="6" md="2" v-if="selectXlsx">
          <v-text-field
            v-model="initDay"
            outlined
            dense
            hide-details="auto"
            placeholder="Dia inicial"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="2" v-if="selectXlsx">
          <v-text-field
            v-model="endDay"
            outlined
            dense
            hide-details="auto"
            placeholder="Dia final"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="2" v-if="selectXlsx">
          <v-text-field
            v-model="initMonth"
            outlined
            dense
            hide-details="auto"
            placeholder="Mes inicial"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="6" md="2" v-if="selectXlsx">
          <v-text-field
            v-model="endMonth"
            outlined
            dense
            hide-details="auto"
            placeholder="Mes final"
            type="number"
          >
          </v-text-field>
        </v-col>
        <v-col cols="12" md="3" v-if="selectXlsx">
          <v-btn @click="formatPaymentsDate()" color="primary">Descargar</v-btn>
        </v-col>
      </v-row>
      <v-row justify="space-between" align="center">
        <v-col cols="12" md="6">
          <v-text-field
            v-model="paymentId"
            label="Busca un pago por su ID"
            outlined
            dense
            hide-details="auto"
            @keypress.enter="loadIndividualPayment()"
          >
            <template v-slot:prepend-inner>
              <v-icon>mdi-magnify</v-icon>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <div id="container"></div>
    </v-col>
  </v-row>
</template>

<script>
import { loadConnectAndInitialize } from "@stripe/connect-js";
import * as XLSX from "xlsx/xlsx.mjs";
export default {
  name: "EmbeddedTest",
  data() {
    return {
      stripe: null,
      paymentId: "",
      selectXlsx: null,
      initDay: "",
      endDay: "",
      initMonth: "",
      endMonth: "",
    };
  },
  mounted() {
    this.loadStripe();
  },
  methods: {
    async loadIndividualPayment() {
      const paymentDetails = this.stripe.create("payment-details");
      paymentDetails.setPayment(this.paymentId);
      const container = document.getElementById("container");
      paymentDetails.setOnClose(() => {
        paymentDetails.remove();
      });
      container.appendChild(paymentDetails);
    },
    async loadStripe() {
      try {
        const fetchClientSecret = async () => {
          // Fetch the AccountSession client secret
          const response = await this.axios({
            method: "GET",
            url: "/users/account-sesion",
          });

          if (response.status !== 200) {
            throw new Error("Failed to fetch AccountSession client secret");
          }

          return response.data.client_secret;
        };

        this.stripe = await loadConnectAndInitialize({
          publishableKey: process.env.VUE_APP_STRIPE_API_KEY,
          fetchClientSecret: fetchClientSecret,
        });

        const paymentComponent = this.stripe.create("payments");
        const container = document.getElementById("container");
        container.appendChild(paymentComponent);
      } catch (error) {
        this.stripeError = error;
      }
    },
    formatPaymentsDate() {
      const reader = new FileReader();
      const csvReader = new FileReader();
      const formattedData = [];
      reader.onload = (e) => {
        /* Parse data */
        const bstr = e.target.result;
        const wb = XLSX.read(bstr, { type: "binary" });
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_csv(ws).split("\n");

        for (const row of data) {
          const formattedRow = row.split(",");

          if (formattedData.length === 0) {
            formattedData.push(formattedRow);
            continue;
          }

          if (formattedRow[1]) {
            const fullDate = formattedRow[1];

            const date = fullDate.split(" ")[0];
            const time = fullDate.split(" ")[1];

            const year = date.split("-")[0];
            const month = date.split("-")[1];
            const day = date.split("-")[2];
            const hour = time.split(":")[0];
            const minutes = time.split(":")[1];
            const seconds = time.split(":")[2];

            console.log(year, month, day, hour, minutes, seconds);

            const formattedDate = new Date(
              Date.UTC(year, month - 1, day, hour, minutes, seconds)
            );

            formattedDate.setHours(formattedDate.getHours() - 6);

            if (
              formattedDate.getDate() >= parseInt(this.initDay) &&
              formattedDate.getDate() <= parseInt(this.endDay) &&
              formattedDate.getMonth() >= parseInt(this.initMonth) - 1 &&
              formattedDate.getMonth() <= parseInt(this.endMonth) - 1
            ) {
              formattedRow[1] = formattedDate.toISOString();
              formattedData.push(formattedRow);
            }
          }
        }

        const wos = XLSX.utils.aoa_to_sheet(formattedData);
        const wob = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wob, wos, "Sheet1");
        XLSX.writeFile(wob, "stripeData.xlsx");
      };

      csvReader.onload = (e) => {
        const csvData = e.target.result;
        const data = csvData.split("\n");
        for (const row of data) {
          const formattedRow = row.split(",");

          if (formattedData.length === 0) {
            formattedData.push(formattedRow);
            continue;
          }

          if (formattedRow[1]) {
            const fullDate = formattedRow[1];

            const date = fullDate.split(" ")[0];
            const time = fullDate.split(" ")[1];

            const year = date.split("-")[0];
            const month = date.split("-")[1];
            const day = date.split("-")[2];
            const hour = time.split(":")[0];
            const minutes = time.split(":")[1];
            const seconds = time.split(":")[2];

            console.log(year, month, day, hour, minutes, seconds);

            const formattedDate = new Date(
              Date.UTC(year, month - 1, day, hour, minutes, seconds)
            );

            formattedDate.setHours(formattedDate.getHours() - 6);

            if (
              formattedDate.getDate() >= parseInt(this.initDay) &&
              formattedDate.getDate() <= parseInt(this.endDay) &&
              formattedDate.getMonth() >= parseInt(this.initMonth) - 1 &&
              formattedDate.getMonth() <= parseInt(this.endMonth) - 1
            ) {
              formattedRow[1] = formattedDate.toISOString();
              formattedData.push(formattedRow);
            }
          }
        }

        console.log(formattedData);

        const ws = XLSX.utils.aoa_to_sheet(formattedData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "stripeData.xlsx");
      };

      //if the file is a xlsx file
      if (this.selectXlsx.name.split(".").pop() === "xlsx") {
        reader.readAsBinaryString(this.selectXlsx);
      } else {
        csvReader.readAsText(this.selectXlsx);
      }
    },
  },
};
</script>

<style>
</style>